
















import PageTitle from "piramis-base-components/src/components/PageTitle.vue"
import ServicesCart from "piramis-base-components/src/plugins/AdminTemplate/pages/services-cart.vue"
import { PaymentMethods } from "piramis-base-components/src/components/ServicesCart/logic/types";

import Vue from 'vue'
import { Component } from "vue-property-decorator"

@Component({
  components: {
    ServicesCart,
    PageTitle,
  },
})
export default class BuyBoard extends Vue {
  payMethods = this.defaultPayMethods()

  defaultPayMethods() {
    return {
      targetIdKey: 'board_key',
      config: {
        target_id: {
          board_key: this.$store.getters.activeBoard.board,
        },
        target_type: 'board'
      }
    }
  }

  onPromocodeActivate(data: Required<PaymentMethods>) {
    if (this.$store.getters.activeBoard) {
      this.$baseTemplate.loader.open()

      this.$store.dispatch('getBoardConfig', this.$store.getters.activeBoard.board)
        .then(res =>{
          if (res) {
            this.$baseTemplate.sidebar.reload()
          }

          this.$baseTemplate.loader.close()
        })
    }
  }
}
